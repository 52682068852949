import React from "react";
import {MapContainer, useMap, useMapEvents} from "react-leaflet";

const maxBounds = [
  [43.3188, -3.0553860003789873],
  [43.24988739824065, -2.9406959804573656]
]

const maxZoom = 18;
const minZoom = 13;

export default ({children, centroMapa, zoomMapa, styleMapa, mapaBounds, locatorFn}) => {

  const SetCenter = ({ coords }) => {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
  }

  const SetBounds = ({bounds}) => {
    const map = useMap();
    if (bounds.length > 1) {
      map.fitBounds(bounds);
    }
    return null;
  }

  const KbusDefaultsMap = () => {
    const map = useMap();
    map.setMaxBounds(maxBounds)
    map.setMaxZoom(maxZoom)
    map.setMinZoom(minZoom)
    return null;
  }

  const LocationProvider = () => {
    useMapEvents({
      click: (e) => {
        console.log(e);
        locatorFn(e.latlng)
      }
    })
    return null
  }

  if (typeof window !== 'undefined') {
    return (
      <MapContainer scrollWheelZoom={false} center={centroMapa} zoom={zoomMapa} style={styleMapa}>
        {children}
        <KbusDefaultsMap/>
        <SetCenter coords={centroMapa}/>
        {mapaBounds && (<SetBounds bounds={mapaBounds}/>)}
        {locatorFn && (<LocationProvider/>)}
      </MapContainer>
    )
  }
  return null
}
